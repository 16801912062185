<script lang="ts" setup>
</script>

<template>
     <div class="flex flex-col items-center justify-center min-h-screen">
      <Wallpaper/>
    <!-- <LanguageSelect class="absolute right-5 top-5"></LanguageSelect> -->
    <slot />
  </div>
</template>

<style scoped></style>
